import request from '@/utils/request';

const PATH = '/v1/index';

// 1.	Banner管理
export function getBanner(params = {}) {
  return request({
    url: PATH + '/banner',
    method: 'GET',
    params,
  });
}

// 2.	首页公司介绍板块
export function getCompany(params = {}) {
  return request({
    url: PATH + '/company',
    method: 'GET',
    params,
  });
}

// 3.	首页浓香火锅板块
export function getPot(params = {}) {
  return request({
    url: PATH + '/pot',
    method: 'GET',
    params,
  });
}

// 7.	浓香火锅图标板块
export function getIco(params = {}) {
  return request({
    url: PATH + '/ico',
    method: 'GET',
    params,
  });
}

// 8.	菜品配置
export function getFood(params = {}) {
  return request({
    url: PATH + '/food',
    method: 'GET',
    params,
  });
}

// 5.首页新闻资讯板块
export function getNews(params = {}) {
  return request({
    url: PATH + '/news',
    method: 'GET',
    params,
  });
}

// 门店环境
export function getStore(params = {}) {
  return request({
    url: PATH + '/store',
    method: 'GET',
    params,
  });
}
