<template>
  <el-dialog
    :width="isMobile ? '90%' : '50%'"
    class="videoModal"
    v-bind="this.$attrs"
    :visible.sync="visible"
    :before-close="beforeClose"
    :modal-append-to-body="false"
  >
    <video
      :src="src"
      :poster="poster"
      preload="auto"
      ref="video"
      controls="controls"
      class="video"
    >您的浏览器不支持 video 标签。</video>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    src: {
      type: String,
      default: '',
    },
    poster: {
      type: String,
      default: '',
    },
  },
  methods: {
    onCanplay() {
      this.$refs.video.play();
    },
    beforeClose() {
      this.$refs?.video?.pause();
      this.onClose();
    },
  },
  computed: {
    ...mapState(['isMobile']),
  },
  watch: {
    visible() {
      if (this.visible) {
        this.$nextTick(() => {
          this.$refs.video.addEventListener('canplay', this.onCanplay);
        });
        return;
      }
      this.$refs.video.removeEventListener('canplay', this.onCanplay);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
.videoModal {
  .video {
    width: 100%;
    vertical-align: bottom;
  }
  ::v-deep .el-dialog {
    border-radius: 6px;
    @media screen and (max-width: $res-point-9) {
      border-radius: 3px;
    }
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__headerbtn {
      font-size: 32px;
      z-index: 99;
      top: 10px;
      right: 10px;
      .el-dialog__close {
        color: #fff;
        vertical-align: bottom;
      }
      @media screen and (max-width: $res-point-9) {
        font-size: 24px;
      }
    }
    .el-dialog__body {
      padding: 8px;
      @media screen and (max-width: $res-point-9) {
        padding: 4px;
      }
    }
  }
}
</style>