<template>
  <div class="home-join">
    <div data-aos="fade-up">
      <p class="title">21年品质火锅诚邀加盟</p>
      <div
        v-if="!isHideDetail"
        class="more"
      ><span @click="goDetail">进一步了解 ></span></div>
    </div>
    <el-form
      ref="form"
      :model="form"
      class="home-join-form"
    >
      <div
        class="box"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        <el-form-item
          prop="name"
          :rules="[
          { required: true, message: '姓名不能为空', trigger: 'blur' },
        ]"
          label=""
        >
          <span class="label"><span class="required">*</span><span class="base-icon-yonghu base-iconfont"></span></span>
          <el-input
            type="name"
            placeholder="请输入您的姓名"
            v-model="form.name"
            class="input"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="tel"
          :rules="[
          { required: true, message: '电话号码不能为空' },
          { type: 'number', message: '电话号码必须为数字值' }
        ]"
          label=""
        >
          <span class="label"><span class="required">*</span><span class="base-icon-dianhua base-iconfont"></span></span>
          <el-input
            type="tel"
            placeholder="请输入您的电话号码"
            v-model.number="form.tel"
            class="input"
          ></el-input>
        </el-form-item>
      </div>
      <el-form-item
        data-aos="fade-up"
        data-aos-delay="400"
      >
        <div class="btn"><span @click="onSubmit">提交</span></div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { leaveMsg } from '@/api/ShuJiuXiang/join';
import { Message } from 'element-ui';
export default {
  props: {
    isHideDetail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        name: '',
        tel: '',
      },
    };
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          await leaveMsg({
            form_name: this.form.name,
            form_tel: this.form.tel,
          });
          Message.success({
            message: '提交成功！',
          });
        } else {
          return false;
        }
      });
    },
    goDetail() {
      this.$router.push('/sjx/join');
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
.home-join {
  width: 996px;
  margin: 0 auto;
  max-width: 90%;
  padding-bottom: math.percentage(math.div(193, 1920));
  .title {
    color: $sub-color-2;
    font-size: 36px;
    line-height: math.div(48, 36);
    letter-spacing: 27px;
    text-align: center;
    margin-bottom: 24px;
  }
  .more {
    text-align: center;
    color: $sub-color-2;
    line-height: math.div(19, 14);
    margin-bottom: 35px;
    > span {
      cursor: pointer;
      @media screen and (min-width: $hover-point) {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .box {
    display: flex;
    justify-content: space-between;
    ::v-deep {
      .el-form-item {
        margin-bottom: 0;
        width: math.percentage(math.div(474, 996));
        position: relative;
      }
    }
  }
  .input {
    ::v-deep {
      .el-input__inner {
        background: none;
        border: none;
        border-bottom: 1px solid $sub-color-4;
        border-radius: 0;
        height: 68px;
        line-height: 1.5;
        font-size: 24px;
        color: $sub-color-2;
        padding: 0 0 0 60px;
        &:-ms-input-placeholder,
        &::-moz-placeholder,
        &:-moz-placeholder,
        &::-webkit-input-placeholder {
          color: $sub-color-4;
        }
      }
    }
  }
  .label {
    position: absolute;
    left: 0;
    line-height: 1;
    display: flex;
    top: 50%;
    margin-top: -14px;
    .required {
      color: $sub-color-1;
      margin-right: 5px;
    }
    .base-iconfont {
      font-size: 28px;
      color: $sub-color-2;
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    margin-top: 68px;
    > span {
      transition: 300ms;
      cursor: pointer;
      position: relative;
      width: 64px;
      height: 64px;
      border: 1px solid $sub-color-4;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $sub-color-4;
      &::before,
      &::after {
        width: 1px;
        height: 32px;
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -16px;
        background: $sub-color-4;
        transition: 300ms;
      }
      &::before {
        right: 100%;
        margin-right: 22px;
      }
      &::after {
        left: 100%;
        margin-left: 22px;
      }
      @media screen and (min-width: $hover-point) {
        &:hover {
          background: $sub-color-2;
          color: #fff;
          border-color: $sub-color-2;
          &::before,
          &::after {
            background: $sub-color-2;
          }
        }
      }
    }
  }
  &-form {
    padding-top: 20px;
  }
  @media screen and (max-width: $res-point-3) {
    .title {
      font-size: 36px * 0.8;
    }
    .input {
      ::v-deep {
        .el-input__inner {
          font-size: 24px * 0.8;
        }
      }
    }
  }
  @media screen and (max-width: $res-point-6) {
    max-width: 100%;
  }
  @media screen and (max-width: $res-point-7) {
    .title {
      font-size: 36px * 0.8 * 0.8;
      letter-spacing: 27px * 0.8;
    }
  }
  @media screen and (max-width: $res-point-10) {
    .title {
      font-size: 36px * 0.8 * 0.8 * 0.8;
      letter-spacing: 27px * 0.8 * 0.8;
      margin-bottom: 24px * 0.8 * 0.8;
    }
    &-form {
      padding-top: 20px * 0.8 * 0.8;
    }
    .input {
      ::v-deep {
        .el-input__inner {
          font-size: 14px;
          height: 68px * 0.8;
          padding-left: 60px * 0.8 * 0.8;
        }
      }
    }
    .label {
      margin-top: -9px;
      .base-iconfont {
        font-size: 18px;
      }
      .required {
        margin-right: 2px;
      }
    }
    .btn {
      margin-top: 68px * 0.8 * 0.8;
      > span {
        width: 54px;
        height: 54px;
        &::before,
        &::after {
          height: 22px;
          margin-top: -11px;
        }
        &::before {
          right: 100%;
          margin-right: 22px;
        }
        &::after {
          left: 100%;
          margin-left: 22px;
        }
      }
    }
    .more {
      margin-bottom: 35px * 0.8 * 0.8;
    }
  }
  @media screen and (max-width: $res-point-9) {
    .title {
      font-size: 15px;
      letter-spacing: 27px * 0.8 * 0.8 * 0.8;
    }
    &-form {
      padding-top: 0;
    }
    .box {
      flex-wrap: wrap;
      ::v-deep {
        .el-form-item {
          width: 100%;
          + .el-form-item {
            margin-top: 20px;
          }
        }
      }
    }
  }
}
</style>