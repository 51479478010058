<template>
  <div class="home-about">
    <div v-loading="loading">
      <div v-if="show">
        <div data-aos="fade-up">
          <div class="sign-box"><img
              alt=""
              :src="require('@/assets/ShuJiuXiang/sign@2x.png')"
            /></div>
          <HomeTitle class="home-about-title">{{ data.rightTitle }}</HomeTitle>
        </div>
        <div class="txts">
          <div>
            <div data-aos="fade-up">
              <p
                class="desc"
                v-html="data.rightRemark"
              ></p>
              <DetailMore @click.native="goDetail" />
            </div>
            <ScaleImage class="home-about-img">
              <PlaceholderImage
                :src="data.rightImage"
                :width="320"
                :height="200"
                :alt="data.rightTitle"
              />
            </ScaleImage>
          </div>
        </div>
      </div>
      <el-empty v-else />
    </div>
  </div>
</template>

<script>
import { HomeTitle } from '../../components/Title';
import { DetailMore } from '@/components/More';
import { Placeholder, Scale } from '@/components/Image';
import { getCompany } from '@/api/ShuJiuXiang/home';
// import Parallax from 'parallax-js';

export default {
  components: {
    HomeTitle,
    DetailMore,
    PlaceholderImage: Placeholder,
    ScaleImage: Scale,
  },
  data() {
    return {
      data: {},
      loading: false,
    };
  },
  mounted() {
    this.getData();
    // setTimeout(() => {
    //   var scene = document.getElementById('scene');
    //   console.log('scene: ', scene);
    //   var parallaxInstance = new Parallax(scene, {
    //     relativeInput: true,
    //   });
    //   parallaxInstance.friction(0.2, 0.2);
    // }, 10000);
  },
  methods: {
    goDetail() {
      const { href } = this.$router.resolve('/sjx/about');
      window.open(href, '_blank');
    },
    async getData() {
      this.loading = true;
      try {
        const res = await getCompany();
        this.loading = false;
        this.data = res;
      } catch (error) {
        this.loading = false;
      }
    },
  },
  computed: {
    show() {
      return JSON.stringify(this.data) !== '{}';
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
.home-about {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: math.percentage(math.div(380, 1920)) math.percentage(math.div(180, 1920))
    math.percentage(math.div(225, 1920));
  .sign-box {
    display: flex;
    justify-content: flex-end;
    padding-right: 90px;
    margin-bottom: 10px;
    > img {
      width: 22px;
      height: 13px;
    }
  }
  &-title {
    color: $sub-color-2;
    text-align: right;
    margin-bottom: 30px;
  }
  &-img {
    margin-top: 50px;
  }
  .txts {
    display: flex;
    justify-content: flex-end;
    color: $text-color-6;
    padding-right: 90px;
    > div {
      width: 320px;
      max-width: 100%;
    }
    .desc {
      text-align: justify;
      font-size: 16px;
      line-height: 32px;
      max-height: 32px * 4;
      overflow: hidden;
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: $res-point-3) {
    padding-left: math.percentage(math.div(180 * 0.8 * 0.8, 1920));
    padding-right: math.percentage(math.div(180 * 0.8 * 0.8, 1920));
  }
  @media screen and (max-width: $res-point-5) {
    padding-left: 5%;
    padding-right: 5%;
  }
  @media screen and (max-width: $res-point-6) {
    padding-top: math.percentage(math.div(2150, 1920));
    .txts {
      padding-right: 0;
    }
    &-img {
      width: 320px;
      max-width: 100%;
    }
  }
  @media screen and (max-width: $res-point-9) {
    &-title {
      margin-bottom: math.div(30, 600) * 100vw;
    }
    .txts {
      .desc {
        font-size: 14px;
        line-height: 2;
        max-height: none;
      }
    }
    &-img {
      margin-top: math.div(50, 600) * 100vw;
      width: 100%;
    }
  }
}
</style>