<template>
  <div class="home-special">
    <div class="res">
      <VerticalTitle
        class="home-special-title"
        title="特色菜品"
        data-aos="fade-up"
      />
      <div
        v-loading="loading"
        data-aos="fade-up"
        :data-aos-delay="200"
      >
        <div
          class="box"
          v-if="specialData.length"
        >
          <swiper
            ref="mySwiper"
            :options="swiperOptions"
          >
            <swiper-slide
              v-for="item in specialData"
              :key="item.id"
            >
              <HomeSpecialCard :data="item" />
            </swiper-slide>
            <div
              class="swiper-button-next"
              slot="button-next"
            >换一组
              <span class="icon">
                <img
                  alt=""
                  class="h"
                  :src="require('@/assets/ShuJiuXiang/icon-home-next@2x.png')"
                />
                <img
                  alt=""
                  class="b"
                  :src="require('@/assets/ShuJiuXiang/b-icon-home-next@2x.png')"
                />
              </span>
            </div>
          </swiper>
        </div>
        <el-empty v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { VerticalTitle } from '@/components/Title';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
import HomeSpecialCard from '../../components/Card/HomeSpecial.vue';
import { getFood } from '@/api/ShuJiuXiang/home';
export default {
  components: {
    VerticalTitle,
    swiper,
    swiperSlide,
    HomeSpecialCard,
  },
  data() {
    const $this = this;
    return {
      specialData: [],
      swiperOptions: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        loop: true,
        loopFillGroupWithBlank: true,
        speed: 1000, // 切换速度
        // autoplay: {
        //   delay: 8000,
        //   pauseOnMouseEnter: true, // 鼠标置于swiper时暂停自动切换，鼠标离开时恢复自动切换
        // },
        autoplay: false,
        grabCursor: true, // 拖动时指针会变成抓手形状
        breakpoints: { 769: { slidesPerView: 1, slidesPerGroup: 1 } },
        navigation: {
          nextEl: '.swiper-button-next',
        },
        on: {
          click: function () {
            const len = $this.specialData.length;
            let index = this.clickedIndex - this.loopedSlides;
            index = index >= len ? index - len : index;
            if (isNaN(index)) return;
            $this.goDetail($this.specialData[index]);
          },
        },
      },
      swiperIndex: 0,
      loading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async goDetail({ id }) {
      this.$router.push(`/sjx/brand/special/detail/${id}`);
    },
    async getData() {
      this.loading = true;
      try {
        const res = await getFood();
        this.loading = false;
        this.specialData = res.map((d) => ({
          id: d.foodId,
          imgUrl: d.foodImage,
          title: d.foodName,
          desc: d.foodContent,
        }));
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
.home-special {
  padding: math.percentage(math.div(144 - 50, 1920)) 0 math.percentage(math.div(108, 1920));
  .res {
    position: relative;
    padding-left: math.percentage(math.div(320, 1920));
    padding-right: math.percentage(math.div(175 - 50, 1920));
  }
  &-title {
    color: $sub-color-2;
    margin-left: math.percentage(math.div(167, 1920));
    position: absolute;
    top: 0;
    left: 0;
    z-index: 29;
  }
  .box {
    flex: 1;
    overflow: hidden;
    position: relative;
  }
  &::v-deep {
    .swiper-container {
      margin-left: -20px;
    }
    .swiper-slide {
      padding-left: 20px;
      &:nth-child(2n - 1) {
        padding-top: math.percentage(math.div(138, 1460));
      }
    }
  }
  @media screen and (max-width: $res-point-5) {
    .res {
      padding-right: 5%;
    }
  }
  @media screen and (max-width: $res-point-6) {
    padding: math.percentage(math.div(168, 1920)) 5%;
    .res {
      padding-left: 80px;
      padding-right: 0;
    }
    &-title {
      margin-left: 0;
    }
    &::v-deep {
      .swiper-container {
        margin-left: -10px;
      }
      .swiper-slide {
        padding-left: 10px;
        &:nth-child(2n - 1) {
          padding-top: 80px;
        }
      }
    }
  }
  @media screen and (max-width: $res-point-13) {
    &::v-deep {
      .swiper-container {
        margin-left: 0;
        padding-top: 70px;
      }
      .swiper-slide {
        padding-left: 0;
        &:nth-child(2n - 1) {
          padding-top: 0;
        }
      }
    }
  }
  @media screen and (max-width: $res-point-8) {
    .res {
      padding-left: 80px * 0.8 * 0.8;
    }
  }
  @media screen and (max-width: $res-point-9) {
    .res {
      padding-left: 80px * 0.8 * 0.8 * 0.8 * 0.8;
    }
    &::v-deep {
      .swiper-container {
        padding-top: 40px;
      }
    }
  }
}
.swiper-button-next {
  right: auto;
  top: 15px;
  left: 20px;
  z-index: 99;
  background: none;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  color: $text-color-3;
  margin: 0;
  transition: 300ms;
  .icon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $text-color-3;
    margin-left: 14px;
    > img {
      width: 20px;
      height: 20px;
    }
    .b {
      display: none;
    }
  }
  @media screen and (min-width: $hover-point) {
    &:hover {
      color: $sub-color-2;
      .icon {
        border-color: $sub-color-2;
        .h {
          display: none;
        }
        .b {
          display: block;
        }
      }
    }
  }
  @media screen and (max-width: $res-point-6) {
    top: 0;
    left: 10px;
  }
  @media screen and (max-width: $res-point-13) {
    left: auto;
    right: 0;
  }
  @media screen and (max-width: $res-point-9) {
    .icon {
      width: 28px;
      height: 28px;
      margin-left: 8px;
      > img {
        width: 10px;
        height: 10px;
      }
    }
  }
}
</style>