<template>
  <div class="home-story">
    <div
      class="home-story-cont"
      v-loading="loading"
    >
      <ScaleImage>
        <PlaceholderImage
          :src="data.potIco"
          :width="1600"
          :height="820"
          :alt="data.potTitle"
        />
        <div class="home-story-txts">
          <div>
            <div class="home-story-intro">
              <HomeTitle
                class="ti"
                data-aos="fade-up"
              >{{ data.potTitle }}</HomeTitle>
              <div
                class="box"
                data-aos="fade-up"
                :data-aos-delay="100"
              >
                <p
                  class="desc"
                  v-html="data.potRemark"
                ></p>
                <DetailMore
                  @click.native="goDetail"
                  :isWhite="true"
                />
              </div>
            </div>
            <div class="home-story-honor">
              <HonorCard
                v-for="(item, index) in honorData"
                :key="item.id"
                :data="item"
                class="item"
                data-aos="fade-left"
                :data-aos-delay="index * 200"
              />
            </div>
          </div>
        </div>
      </ScaleImage>
    </div>
  </div>
</template>

<script>
import { Placeholder, Scale } from '@/components/Image';
import { HomeTitle } from '../../components/Title';
import { DetailMore } from '@/components/More';
import HonorCard from '../../components/Card/Honor.vue';
import { getPot, getIco } from '@/api/ShuJiuXiang/home';

export default {
  components: {
    PlaceholderImage: Placeholder,
    HomeTitle,
    DetailMore,
    HonorCard,
    ScaleImage: Scale,
  },
  data() {
    return {
      honorData: [],
      data: {},
      loading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    goDetail() {
      const { href } = this.$router.resolve('/sjx/brand/story');
      window.open(href, '_blank');
    },
    async getData() {
      this.loading = true;
      try {
        const resPot = await getPot();
        const resIco = await getIco();
        this.loading = false;
        this.data = resPot;
        this.honorData = resIco.map((d) => ({
          id: d.icoId,
          icon: d.icoImage,
          text: d.icoTitle,
        }));
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
@import '@/styles/mixin.scss';
.home-story {
  padding-left: math.percentage(math.div(320, 1920));
  &-cont {
    position: relative;
  }
  &-txts {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0 math.percentage(math.div(327, 1600)) 0 math.percentage(math.div(226, 1600));
    > div {
      flex: auto;
      display: flex;
      justify-content: space-between;
    }
  }
  &-honor {
    .item {
      + .item {
        margin-top: 50px;
      }
    }
  }
  &-intro {
    .box {
      width: 320px;
      margin-left: 168px;
      max-width: 100%;
    }
    .ti {
      margin-bottom: 30px;
    }
    .desc {
      line-height: 24px;
      min-height: 24px * 3;
      overflow: hidden;
      margin-bottom: 30px;
      @include desc-wrap(3);
    }
  }
  @media screen and (max-width: $res-point-2) {
    &-txts {
      padding-right: math.percentage(math.div(190, 1600));
    }
  }
  @media screen and (max-width: $res-point-3) {
    &-txts {
      padding-left: math.percentage(math.div(190, 1600));
    }
  }
  @media screen and (max-width: $res-point-5) {
    &-txts {
      padding-left: 5%;
      padding-right: 5%;
    }
  }
  @media screen and (max-width: $res-point-6) {
    padding-left: 0;
  }
  @media screen and (max-width: $res-point-7) {
    overflow: hidden;
    &-txts {
      position: relative;
      z-index: 9;
      padding-top: 80px;
      padding-bottom: 80px;
      > div {
        flex-wrap: wrap;
      }
    }
    &-honor {
      margin-top: 50px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      .item {
        &:nth-child(1),
        + .item {
          margin-top: 30px;
        }
      }
    }
    ::v-deep {
      .placeholderImage {
        position: absolute;
        padding-bottom: 0 !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  @media screen and (max-width: $res-point-8) {
    &-intro {
      .box {
        margin-left: 0;
      }
      .ti {
        margin-bottom: math.div(30, 600) * 100vw;
      }
    }
    &-txts {
      padding-top: math.div(80, 600) * 100vw;
      padding-bottom: math.div(80, 600) * 100vw;
    }
    &-honor {
      margin-top: math.div(50, 600) * 100vw;
    }
  }
  @media screen and (max-width: $res-point-9) {
    &-honor {
      .item {
        &:nth-child(1),
        + .item {
          margin-top: 15px;
        }
      }
    }
  }
}
</style>