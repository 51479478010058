<template>
  <div class="home-env">
    <VerticalTitle
      title="门店环境"
      class="home-env-title"
      data-aos="fade-up"
      :data-aos-delay="200"
    />
    <HomeEnvCarousel
      data-aos="fade-up"
      :data="data"
    />
    <div
      class="home-env-more"
      @click="goDetail"
    >更多</div>
  </div>
</template>

<script>
import { HomeEnvCarousel } from '../../components/Carousel';
import { VerticalTitle } from '@/components/Title';
import { getStore } from '@/api/ShuJiuXiang/home';

export default {
  components: {
    HomeEnvCarousel,
    VerticalTitle,
  },
  data() {
    return {
      data: [],
      loading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    goDetail() {
      const { href } = this.$router.resolve('/sjx/brand/env');
      window.open(href, '_blank');
    },
    async getData() {
      this.loading = true;
      try {
        const res = await getStore();
        this.loading = false;
        this.data = res.map((d) => ({
          id: d.storeId,
          imgUrl: d.storeImageUrl,
          title: d.parentStoreTitle,
          desc: d.parentRemark,
        }));
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
.home-env {
  margin-bottom: math.percentage(math.div(185, 1920));
  position: relative;
  &-title {
    color: $sub-color-2;
    position: absolute;
    top: 0;
    right: 0;
    padding-top: math.percentage(math.div(100, 1920));
    padding-right: math.percentage(math.div(147, 1920));
    z-index: 29;
  }
  &-more {
    position: absolute;
    right: 30px;
    bottom: -24px;
    z-index: 29;
    width: 59px;
    height: 59px;
    border-radius: 50%;
    background: $sub-color-5;
    border: 1px solid $sub-color-4;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $sub-color-4;
    cursor: pointer;
    transition: 300ms;
    @media screen and (min-width: $hover-point) {
      &:hover {
        background: $sub-color-2;
        border-color: $sub-color-2;
        color: #fff;
      }
    }
  }
  @media screen and (max-width: $res-point-6) {
    &-more {
      right: 0;
      margin-right: 5%;
    }
    &-title {
      padding-right: 5%;
    }
  }
  @media screen and (max-width: $res-point-9) {
    &-more {
      width: 49px;
      height: 49px;
      bottom: -19px;
      font-size: 12px;
    }
  }
}
</style>