<template>
  <div class="home-news">
    <FixBg :src="require('@/assets/ShuJiuXiang/home-news-bg.jpg')">
      <div class="box box1">
        <HomeTitle
          data-aos="fade-up"
          class="homeTitle"
        >最新资讯</HomeTitle>
      </div>
    </FixBg>
    <div class="box box2">
      <div v-loading="loading">
        <div
          class="newsList"
          v-if="newsData.length"
        >
          <HomeNewsItem
            class="item"
            v-for="(item, index) in newsData"
            :key="item.id"
            :data="item"
            @click.native="goDetail(item)"
            data-aos="fade-up"
            :data-aos-delay="index * 200 + 200"
          />
        </div>
        <el-empty
          style="background: #fff"
          v-else
        />
      </div>
      <div class="moreBox">
        <SJXMore @click.native="goMore" />
      </div>
    </div>
  </div>
</template>

<script>
import { FixBg } from '@/components/Image';
import { HomeTitle } from '../../components/Title';
import HomeNewsItem from '../../components/Card/HomeNews.vue';
import { SJXMore } from '@/components/More';
import { getNews } from '@/api/ShuJiuXiang/home';
export default {
  components: {
    FixBg,
    HomeTitle,
    HomeNewsItem,
    SJXMore,
  },
  data() {
    return {
      newsData: [],
      loading: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    goMore() {
      const { href } = this.$router.resolve('/sjx/news/notice');
      window.open(href, '_blank');
    },
    // 查看详情
    goDetail(itemData) {
      const { flag, link, id } = itemData;
      // 外链接
      if (flag === '1') {
        window.open(link, '_blank');
      } else {
        const { href } = this.$router.resolve(`/sjx/news/detail/notice/${id}`);
        window.open(href, '_blank');
      }
    },
    async getData() {
      this.loading = true;
      try {
        const res = await getNews();
        this.loading = false;
        this.newsData = res.map((d) => {
          const { createTime = '' } = d;
          return {
            id: d.newsId,
            imgUrl: d.newsImage,
            title: d.newsTitle,
            year: createTime.slice(0, 4),
            month: createTime.slice(6, 7)
              ? `${createTime.slice(6, 7)}月${createTime.slice(8, 9)}日`
              : '',
            flag: d.newsLinkFlag,
            link: d.newsLink,
          };
        });
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
.home-news {
  padding-bottom: math.percentage(math.div(128, 1920));
  .box {
    width: 1390px;
    max-width: 90%;
    margin: 0 auto;
  }
  .box1 {
    padding: math.percentage(math.div(275, 1920)) 0 (260px + 150px);
  }
  .box2 {
    margin-top: -260px;
  }
  .homeTitle {
    text-align: center;
    color: #fff;
  }
  .moreBox {
    margin-top: 60px;
  }
  @media screen and (max-width: $res-point-6) {
    .box2 {
      margin-top: math.percentage(math.div(-260, 1920));
    }
    .box1 {
      padding-bottom: math.percentage(math.div((260 + 150), 1920));
    }
  }
  @media screen and (max-width: $res-point-9) {
    padding-bottom: math.percentage(math.div(168, 1920));
    .moreBox {
      margin-top: math.div(50, 600) * 100vw;
    }
  }
}
.newsList {
  display: flex;
  margin-left: -20px;
  .item {
    width: 33.33%;
  }
  ::v-deep {
    .scaleImg {
      padding-left: 20px;
    }
  }
  @media screen and (max-width: $res-point-13) {
    .item {
      width: 50%;
      &:nth-child(n + 3) {
        display: none;
      }
    }
  }
  @media screen and (max-width: $res-point-9) {
    flex-wrap: wrap;
    margin: 0;
    ::v-deep {
      .scaleImg {
        padding-left: 0;
      }
    }
    .item {
      width: 100%;
      + .item {
        margin-top: 15px;
      }
      &:nth-child(n + 3) {
        display: block;
      }
    }
  }
}
.moreBox {
  display: flex;
  justify-content: center;
}
</style>