<template>
  <!-- @click="clickHandle" -->
  <div class="home-special-card">
    <ScaleImage class="box">
      <PlaceholderImage
        :src="data.imgUrl"
        :width="720"
        :height="767"
        :alt="data.title"
      />
      <div class="txts">
        <p class="title">{{ data.title }}</p>
        <p
          class="desc"
          v-html="data.desc"
        ></p>
        <DetailMore />
      </div>
    </ScaleImage>
  </div>
</template>

<script>
import { Placeholder, Scale } from '@/components/Image';
import { DetailMore } from '@/components/More';
export default {
  components: {
    PlaceholderImage: Placeholder,
    ScaleImage: Scale,
    DetailMore,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    // clickHandle() {
    //   this.$emit('onClick', this.data);
    // },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/mixin.scss';
@import '@/styles/ShuJiuXiang/theme.scss';
.home-special-card {
  .box {
    position: relative;
    padding-bottom: 110px;
    @media screen and (min-width: $hover-point) {
      &:hover {
        .txts {
          transform: none;
          @include show;
        }
      }
    }
  }
  .txts {
    position: absolute;
    bottom: 0;
    left: math.percentage(math.div(120, 720));
    right: math.percentage(math.div(120, 720));
    background: #fff;
    height: 280px;
    padding: 46px 43px 0;
    @include hide;
    transition: 600ms;
    transform: translateY(-20%);
    border-radius: 2px;
    overflow: hidden;
  }
  .title {
    font-size: 28px;
    line-height: math.div(37, 28);
    @include text-nowrap;
    margin-bottom: 16px;
  }
  .desc {
    width: 320px;
    max-width: 100%;
    line-height: 24px;
    height: 24px * 3;
    overflow: hidden;
    margin-bottom: 26px;
    @include desc-wrap(3);
  }
  @media screen and (max-width: $res-point-3) {
    .txts {
      left: 60px;
      right: 60px;
      height: 280px * 0.8;
      padding: 35px 30px 0;
    }
    .title {
      font-size: 28px * 0.8;
      margin-bottom: 16px * 0.8;
    }
    .box {
      padding-bottom: 110px * 0.8;
    }
  }
  @media screen and (max-width: $res-point-6) {
    .txts {
      transform: none;
      @include show;
      left: 30px;
      right: 30px;
      height: 280px * 0.8 * 0.8;
      padding-top: 25px;
    }
    .desc {
      height: 24px * 2;
      margin-bottom: 20px;
      @include desc-wrap(2);
    }
    .box {
      padding-bottom: 110px * 0.8 * 0.8;
    }
  }
  @media screen and (max-width: $res-point-8) {
    ::v-deep {
      .placeholderImage {
        border-radius: 2px;
      }
    }
  }
  @media screen and (max-width: $res-point-9) {
    .txts {
      left: 15px;
      right: 15px;
      padding: 18px 15px 0;
      height: 145px;
    }
    .title {
      font-size: 18px;
      margin-bottom: 8px;
    }
    .desc {
      margin-bottom: 15px;
    }
  }
}
</style>