<template>
  <div
    v-loading="loading"
    class="home-banner"
    :style="{ height: this.isMobile ? 'auto' : `${visibleWH.height}px` }"
  >
    <swiper
      ref="mySwiper"
      :options="swiperOptions"
      v-if="data.length"
    >
      <swiper-slide
        v-for="(item, index) in data"
        :key="index"
      >
        <HomeBannerCard :src="item.imgUrl" />
        <div class="txts">
          <div class="box">
            <p
              class="desc1"
              data-swiper-parallax="-300"
            >{{ item.desc1 }}</p>
            <p
              class="desc2"
              data-swiper-parallax="-200"
            >{{ item.desc2 }}</p>
          </div>
        </div>
      </swiper-slide>
      <div
        slot="pagination"
        class="pagination"
      ></div>
      <div
        class="swiper-button-prev base-iconfont base-icon-zuojiantou"
        slot="button-prev"
      >
      </div>
      <div
        class="swiper-button-next base-iconfont base-icon-youjiantou"
        slot="button-next"
      >
      </div>
    </swiper>
    <VideoModal
      :src="video.url"
      :poster="video.poster"
      :visible="video.show"
      :onClose="() => { video.show = false }"
    />
    <SJXPoint v-if="!isMobile" />
  </div>
</template>

<script>
import HomeBannerCard from '../../components/Card/HomeBanner.vue';
import { mapState } from 'vuex';
import VideoModal from '@/components/Video/VideoModal.vue';
import { SJXPoint } from '@/components/DownPoint';
import { getBanner } from '@/api/ShuJiuXiang/home';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';

export default {
  components: {
    HomeBannerCard,
    swiperSlide,
    swiper,
    VideoModal,
    SJXPoint,
  },
  data() {
    const $this = this;
    return {
      data: [],
      loading: false,
      video: {
        show: false,
        url: '',
        poster: '',
      },
      swiperOptions: {
        loop: true,
        effect: 'fade', // 渐变
        parallax: true,
        speed: 1000, // 切换速度
        autoplay: {
          delay: 5000,
          pauseOnMouseEnter: false, // 鼠标置于swiper时暂停自动切换，鼠标离开时恢复自动切换
          disableOnInteraction: false, // 用户操作swiper之后，是否禁止autoplay
        },
        // grabCursor: true, // 拖动时指针会变成抓手形状
        pagination: {
          el: '.pagination',
          clickable: true,
          bulletClass: 'p-item',
          bulletActiveClass: 'p-active-item',
          renderBullet: function (index, className) {
            const { videoUrl } = $this.data[index];
            return videoUrl
              ? `<span class="${className}"><span class="line"></span><span data-index="${index}" class="icon videoBtn"></span></span>`
              : `<span class="${className}"><span class="line"></span></span>`;
          },
        },
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        },
      },
    };
  },
  mounted() {
    this.getData();
  },
  destroyed() {
    const el = document.querySelector('.pagination');
    if (el && !this.isMobile && this.data.length) {
      el.removeEventListener('click', this.playVideo);
    }
  },
  methods: {
    playVideo(ev) {
      const { target } = ev;
      if (target?.className?.includes('videoBtn')) {
        const index = target.getAttribute('data-index');
        const { videoUrl = '', imgUrl = '' } = this.data[parseInt(index, 10)];
        this.video.show = true;
        this.video.url = videoUrl;
        this.video.poster = imgUrl;
      }
    },
    async getData() {
      this.loading = true;
      try {
        const res = await getBanner();
        this.loading = false;
        this.data = res.map((d) => ({
          id: d.bannerId,
          imgUrl: d.bannerImage,
          videoUrl: d.bannerVideo,
          desc1: d.bannerTitle,
          desc2: d.bannerRemark,
        }));
      } catch (error) {
        this.loading = false;
      }
    },
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper?.swiper;
    },
    ...mapState(['isMobile', 'visibleWH']),
  },
  watch: {
    data() {
      if (this.data.length) {
        const el = document.querySelector('.pagination');
        if (el && !this.isMobile) {
          document.addEventListener('click', this.playVideo);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
@import '@/styles/mixin.scss';
.home-banner {
  height: 100vh;
  position: relative;
  overflow: hidden;
  background: #fff;
  .txts {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: math.percentage(math.div(303, 1920));
    p {
      font-size: 36px;
      line-height: math.div(48, 36);
      letter-spacing: 35px;
      + p {
        margin-top: 29px;
      }
    }
    .desc2 {
      padding-left: 90px;
    }
  }
  ::v-deep {
    .swiper-container {
      height: 100%;
    }
    .home-banner-card {
      img {
        transform: scale(1.1);
        transition: 800ms;
        @include hide;
      }
    }
    .swiper-slide-active {
      .home-banner-card {
        img {
          transform: none;
          @include show;
        }
      }
    }
  }
  @media screen and (max-width: $res-point-6) {
    height: auto;
    .txts {
      padding: 0 5%;
    }
  }
  @media screen and (max-width: $res-point-8) {
    .txts {
      p {
        font-size: 36px * 0.8;
        letter-spacing: 35px * 0.8;
        + p {
          margin-top: 29px * 0.8;
        }
      }
      .desc2 {
        padding-left: math.div(90, 600) * 100vw;
      }
    }
  }
  @media screen and (max-width: $res-point-10) {
    .txts {
      p {
        font-size: 36px * 0.8 * 0.8;
        letter-spacing: 35px * 0.8 * 0.8;
        + p {
          margin-top: 29px * 0.8 * 0.8;
        }
      }
    }
  }
  @media screen and (max-width: $res-point-9) {
    .txts {
      p {
        font-size: 36px * 0.8 * 0.8 * 0.8;
        letter-spacing: 35px * 0.8 * 0.8 * 0.8;
        + p {
          margin-top: 29px * 0.8 * 0.8 * 0.8;
        }
      }
    }
  }
}
.pagination {
  position: absolute;
  left: 50px;
  bottom: 0;
  width: auto;
  z-index: 59;
  padding-bottom: math.percentage(math.div(260, 1920));
  ::v-deep {
    .p-item {
      width: 32px;
      height: 1px;
      display: block;
      position: relative;
      .line {
        position: absolute;
        top: 0;
        height: 100%;
        left: 0;
        width: 16px;
        background: #fff;
        transition: 400ms linear;
      }
      .icon {
        transition: 400ms linear;
        cursor: pointer;
        @include hide;
        transform: translateX(-20px);
        width: 49px;
        height: 49px;
        position: absolute;
        background: url('../../../../assets/ShuJiuXiang/home-video.png') no-repeat center;
        background-size: cover;
        line-height: 1;
        left: 100%;
        top: -24px;
        color: #fff;
        margin-left: 48px;
        &::after {
          content: '';
          width: 23px;
          top: 24px;
          position: absolute;
          right: 100%;
          height: 1px;
          background: #fff;
          margin-right: -5px;
        }
      }
      + .p-item {
        margin-top: 30px;
      }
    }
    .p-active-item {
      .icon {
        @include show;
        transform: none;
      }
      .line {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: $res-point-6) {
    bottom: 30px;
    left: 0;
    right: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    ::v-deep {
      .p-item {
        background: #fff;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        .line,
        .icon {
          display: none;
        }
        + .p-item {
          margin-top: 0;
          margin-left: 30px;
        }
      }
      .p-active-item {
        background: $sub-color-2;
      }
    }
  }
  @media screen and (max-width: $res-point-8) {
    bottom: math.div(30, 600) * 100vw;
    ::v-deep {
      .p-item {
        + .p-item {
          margin-left: math.div(30, 600) * 100vw;
        }
      }
    }
  }
}
.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  margin-top: -20px;
  z-index: 29;
  font-size: 40px;
  line-height: 1;
  color: #fff;
  background: none;
  text-align: center;
  transition: 300ms;
  @media screen and (min-width: $hover-point) {
    opacity: 0.7;
  }
  @media screen and (max-width: $res-point-6) {
    display: none;
  }
}
.swiper-button-prev {
  left: 40px;
}
.swiper-button-next {
  right: 40px + 180px;
}
</style>