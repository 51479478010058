<template>
  <div
    class="home-banner-card"
    ref="box"
    :style="{ height: this.isMobile ? 'auto' : `${visibleWH.height}px` }"
  >
    <img
      ref="img"
      alt=""
      :src="src"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { calculateToCenter } from '@/utils/common';
import _ from 'lodash';
export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    imgWH: {
      type: Array,
      default: () => [1920, 1080],
    },
  },
  mounted() {
    this.init();
    window.addEventListener('resize', _.debounce(this.init, 50));
  },
  methods: {
    init() {
      if (!this.isMobile) {
        const { width, height } = this.visibleWH;
        calculateToCenter(this.$refs.img, ...this.imgWH, width, height);
      } else if (this.$refs.img) {
        this.$refs.img.removeAttribute('style');
      }
    },
  },
  computed: {
    ...mapState(['isMobile', 'visibleWH']),
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/ShuJiuXiang/theme.scss';
.home-banner-card {
  height: 100vh;
  overflow: hidden;
  position: relative;
  img {
    position: relative;
    display: block;
  }
  @media screen and (max-width: $res-point-6) {
    height: auto;
    img {
      width: 100%;
      position: static;
    }
  }
}
</style>

