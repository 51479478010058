
<template>
  <div class="page-home">
    <PageContent class="content">
      <HomeBanner />
      <div class="box">
        <div
          class="home-bg"
          id="scene"
        >
          <img
            class="item bg_8"
            alt=""
            :src="require('@/assets/ShuJiuXiang/home_bg_8.png')"
          />
          <img
            class="item bg_6"
            alt=""
            data-depth="-0.4"
            :src="require('@/assets/ShuJiuXiang/home_bg_6.png')"
          />
          <img
            class="item bg_7"
            alt=""
            data-depth="-0.4"
            :src="require('@/assets/ShuJiuXiang/home_bg_7.png')"
          />
          <img
            class="item bg_1"
            alt=""
            data-depth="0.6"
            :src="require('@/assets/ShuJiuXiang/home_bg_1.png')"
          />
          <img
            class="item bg_2"
            alt=""
            data-depth="-0.2"
            :src="require('@/assets/ShuJiuXiang/home_bg_2.png')"
          />
          <img
            class="item bg_3"
            alt=""
            data-depth="0.4"
            :src="require('@/assets/ShuJiuXiang/home_bg_3.png')"
          />
          <img
            class="item bg_4"
            alt=""
            data-depth="-0.6"
            :src="require('@/assets/ShuJiuXiang/home_bg_4.png')"
          />
          <img
            class="item bg_5"
            alt=""
            data-depth="0.2"
            :src="require('@/assets/ShuJiuXiang/home_bg_5.png')"
          />
        </div>
        <HomeAbout />
        <HomeStory />
        <HomeSpecial />
        <HomeEnv />
        <HomeNews />
        <HomeJoin />
      </div>
    </PageContent>
  </div>
</template>

<script>
import HomeNews from './components/News.vue';
import HomeJoin from './components/Join.vue';
import HomeEnv from './components/Env.vue';
import PageContent from '../components/Layout/PageContent.vue';
import HomeBanner from './components/Banner.vue';
import HomeAbout from './components/About.vue';
import HomeStory from './components/Story.vue';
import HomeSpecial from './components/Special.vue';
import { mapState } from 'vuex';
import Parallax from 'parallax-js';

export default {
  components: {
    HomeNews,
    PageContent,
    HomeJoin,
    HomeEnv,
    HomeBanner,
    HomeAbout,
    HomeStory,
    HomeSpecial,
  },
  computed: {
    ...mapState(['isMobile', 'isNearMobile']),
  },
  mounted() {
    setTimeout(() => {
      const scene = document.getElementById('scene');
      if (scene) {
        new Parallax(scene);
      }
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
.page-home {
  .content {
    padding: 0;
    position: relative;
  }
  .box {
    position: relative;
  }
  .home-bg {
    position: absolute !important;
    z-index: -1;
    width: 100%;
    left: 0;
    top: 0;
    .item {
      position: absolute;
      top: 0;
      left: 0;
    }
    .bg_1 {
      margin: math.percentage(math.div(159, 1920)) 0 0 math.percentage(math.div(653, 1920));
      width: math.percentage(math.div(321, 1920));
    }
    .bg_2 {
      margin: math.percentage(math.div(429, 1920)) 0 0 math.percentage(math.div(100, 1920));
      width: math.percentage(math.div(120, 1920));
    }
    .bg_3 {
      margin: math.percentage(math.div(393, 1920)) 0 0 math.percentage(math.div(279, 1920));
      width: math.percentage(math.div(422, 1920));
    }
    .bg_4 {
      margin: math.percentage(math.div(783, 1920)) 0 0 math.percentage(math.div(788, 1920));
      width: math.percentage(math.div(243, 1920));
    }
    .bg_5 {
      margin: math.percentage(math.div(659, 1920)) 0 0 math.percentage(math.div(714, 1920));
      width: math.percentage(math.div(119, 1920));
    }
    .bg_6 {
      margin: math.percentage(math.div(164, 1920)) 0 0 math.percentage(math.div(354, 1920));
      width: math.percentage(math.div(132, 1920));
    }
    .bg_7 {
      margin: math.percentage(math.div(808, 1920)) 0 0 math.percentage(math.div(354, 1920));
      width: math.percentage(math.div(131, 1920));
    }
    .bg_8 {
      margin: math.percentage(math.div(695, 1920)) 0 0 0;
      width: math.percentage(math.div(1128, 1920));
    }
  }
  @media screen and (max-width: $res-point-6) {
    padding-top: 60px;
    ::v-deep {
      .home-join {
        max-width: 90%;
      }
    }
    .home-bg {
      .bg_1 {
        margin: math.percentage(math.div(159, 1024)) 0 0 math.percentage(math.div(653, 1024));
        width: math.percentage(math.div(321, 1024));
      }
      .bg_2 {
        margin: math.percentage(math.div(429, 1024)) 0 0 math.percentage(math.div(100, 1024));
        width: math.percentage(math.div(120, 1024));
      }
      .bg_3 {
        margin: math.percentage(math.div(393, 1024)) 0 0 math.percentage(math.div(279, 1024));
        width: math.percentage(math.div(422, 1024));
      }
      .bg_4 {
        margin: math.percentage(math.div(783, 1024)) 0 0 math.percentage(math.div(788, 1024));
        width: math.percentage(math.div(243, 1024));
      }
      .bg_5 {
        margin: math.percentage(math.div(659, 1024)) 0 0 math.percentage(math.div(714, 1024));
        width: math.percentage(math.div(119, 1024));
      }
      .bg_6 {
        margin: math.percentage(math.div(164, 1024)) 0 0 math.percentage(math.div(354, 1024));
        width: math.percentage(math.div(132, 1024));
      }
      .bg_7 {
        margin: math.percentage(math.div(808, 1024)) 0 0 math.percentage(math.div(354, 1024));
        width: math.percentage(math.div(131, 1024));
      }
      .bg_8 {
        width: 80%;
      }
    }
  }
}
</style>