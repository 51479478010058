<template>
  <div class="newsItem">
    <ScaleImage>
      <PlaceholderImage
        :width="450"
        :height="260"
        :src="data.imgUrl"
        :alt="data.title"
      />
      <div class="txts">
        <p class="title">{{ data.title }}</p>
        <div class="box">
          <div class="date">{{data.year }}，{{ data.month }}</div>
          <div class="more">了解详情
            <img
              :src="require('@/assets/ShuJiuXiang/b-icon-detail@2x.png')"
              alt=""
              class="b"
            />
            <img
              :src="require('@/assets/ShuJiuXiang/g-icon-detail@2x.png')"
              alt=""
              class="g"
            />
          </div>
        </div>
      </div>
    </ScaleImage>
  </div>
</template>

<script>
import { Scale, Placeholder } from '@/components/Image';
export default {
  components: {
    ScaleImage: Scale,
    PlaceholderImage: Placeholder,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/ShuJiuXiang/theme.scss';
@import '@/styles/mixin.scss';
.newsItem {
  cursor: pointer;
  .txts {
    background: #fff;
    height: 150px;
    padding: 28px 30px 0 35px;
  }
  .title {
    font-size: 16px;
    line-height: 24px;
    height: 24px * 2;
    overflow: hidden;
    @include desc-wrap(2);
  }
  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    padding-top: 30px;
  }
  .date {
    color: $major-color;
  }
  .more {
    display: flex;
    align-items: center;
    transition: 300ms;
    > img {
      width: 26px;
      height: 21px;
      margin-left: 10px;
    }
    .g {
      display: none;
    }
  }
  @media screen and (min-width: $hover-point) {
    &:hover {
      .more {
        color: $major-color;
        .g {
          display: block;
        }
        .b {
          display: none;
        }
      }
    }
  }
  @media screen and (max-width: $res-point-6) {
    .txts {
      padding: 18px 15px 0 15px;
      height: 135px;
    }
  }
  @media screen and (max-width: $res-point-9) {
    overflow: hidden;
    box-shadow: $card-shadow-1;
    border-radius: 6px;
  }
}
</style>