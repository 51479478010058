<template>
  <div class="honorCard">
    <img
      alt=""
      class="ms-l"
      :src="require('@/assets/ShuJiuXiang/j-ms-left@2x.png')"
    />
    <div class="c">
      <span
        class="sjx-iconfont"
        :class="data.icon"
      ></span>
      <p class="text">{{ data.text }}</p>
    </div>
    <img
      alt=""
      class="ms-r"
      :src="require('@/assets/ShuJiuXiang/j-ms-right@2x.png')"
    />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/mixin.scss';
@import '@/styles/ShuJiuXiang/theme.scss';
.honorCard {
  width: 230px;
  display: flex;
  align-items: center;
  > img {
    width: 45px;
    height: 45px;
  }
  .c {
    flex: 1;
    overflow: hidden;
    text-align: center;
  }
  .sjx-iconfont {
    font-size: 34px;
    line-height: 1;
  }
  .text {
    font-size: 16px;
    line-height: math.div(21, 16);
    @include text-nowrap;
    margin-top: 10px;
  }
  @media screen and (max-width: $res-point-9) {
    width: 50%;
    .text {
      font-size: 12px;
      margin-top: 5px;
    }
    .sjx-iconfont {
      font-size: 34px * 0.8 * 0.8;
    }
    > img {
      width: 30px;
      height: 30px;
    }
  }
}
</style>